import React, { useEffect, useState } from "react";
import "./Styles/MemoPage.css";
import Loader from "react-loader-spinner";
import { useParams } from "react-router-dom";
import logo from "../Assets/logo.png";
// import { UserContext } from "../Context";
import { db } from "../firebase";
import { Col, Row } from "react-bootstrap";

function MemoPage() {
  // const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const { customer_num, bill_num } = useParams();
  const [billDetails, setBillDetails] = useState([]);
  const [prodList, setProdList] = useState([]);
  const [product, setProduct] = useState([]);

  useEffect(() => {
    setLoading(true);
    let mount = true;
    const getData = async () => {
      const billData = await db
        .collection("customers")
        .doc(customer_num)
        .collection("bills")
        .doc(bill_num)
        .get();

      if (mount) {
        const jobs = billData.data();
        setBillDetails(jobs);

        const prodData = jobs.jobData?.product;
        setProdList(prodData);
      }
    };

    getData();
    setLoading(false);
    return () => (mount = false);
  }, [customer_num, bill_num]);

  useEffect(() => {
    setLoading(true);
    let mount = true;
    prodList.forEach((prod) => {
      setProduct([]);
      const job = async () => {
        const data = await db
          .collection("jobs")
          .where("jobID", "==", `${prod.billNo}`)
          .get();
        if (data.empty) {
          setProduct([]);
          return;
        } else {
          data.forEach((doc) => {
            if (mount) {
              setProduct((product) => [...product, doc.data().product]);
            }
          });
          return;
        }
      };
      job();
    });

    setLoading(false);
    return () => (mount = false);
  }, [prodList]);

  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "unset";
  }, [loading]);

  //set User
  // if (!context.user?.uid || context.user?.whoAmI === "eng") {
  //   return <Redirect to="/signin" />;
  // }

  const customer = billDetails.jobData?.customer;
  const misc = billDetails.jobData?.misc;

  const time = new Date(parseInt(billDetails.timestamp));
  const billTime = time.toLocaleString();

  return (
    <div className="memoPage">
      <div className="bg__image">
        <img src={logo} alt="" />
      </div>
      {loading && (
        <div className="loading">
          <Loader
            visible={loading}
            type="BallTriangle"
            color="#ffffff"
            height={80}
            width={80}
          />
        </div>
      )}
      <div className="memoPage__header">
        <h1>COMPUFACT SYSTEMS</h1>
        <h6>50 SUBODH GARDEN (1st FLOOR) BANSDRONI KOLKATA - 700070</h6>
        <h6>Call For Service Status - 9830723620 / 8777626185</h6>
        <h6>
          Email : compufactsystems@gmail.com Website: www.compufactsystems.com
        </h6>
        <h5>
          Laptop & Desktop Motherboard Chip Level Repairing, Data Recovery
          Specialist
        </h5>
      </div>
      <h4>Product Recevied Memo</h4>
      <h5>Customer Details: </h5>
      <Row className="memoPage__customer">
        <Col xs={12}>
          <p>
            <b>Created Time:</b> {billTime}
          </p>
        </Col>
        <Col>
          <p>
            <b>Name:</b> {customer?.name}
          </p>
          <p>
            <b>Address:</b> {customer?.address}
          </p>
        </Col>
        <Col>
          <p>
            <b>Number:</b> {customer?.number}
          </p>
          <p>
            <b>Email:</b> {customer?.email}
          </p>
        </Col>
      </Row>
      <div className="memoPage__productSec">
        <h5>Product Details: </h5>
        {product?.map((prod) => (
          <div className="memoPage__product" key={`${prod.billNo}`}>
            <h6>
              <b>JOB NO: {prod.billNo}</b>
            </h6>
            <ul>
              <Row>
                <Col>
                  <li>
                    <b>Product :</b> {prod.product}
                  </li>
                  <li>
                    <b>Brand :</b> {prod.brand}
                  </li>
                  <li>
                    <b>Model No :</b> {prod.model}
                  </li>
                  <li>
                    <b>Serial No :</b> {prod.serial}
                  </li>
                </Col>
                <Col>
                  <li>
                    <b>Other Accs :</b> {prod.accessories}
                  </li>
                  <li>
                    <b>Problem :</b> {prod.problem}
                  </li>

                  {/*                   
                   <li>
                    <b>Spare Required :</b> {prod.spareRequired}
                  </li>
                  */}
                  {/* 
                  <li>
                    <b>Spare Replaced :</b> {prod.spareReplaced}
                  </li> */}
                  <li>
                    <b>Remark :</b> {prod.remark}
                  </li>
                  <li>
                    <b>Status :</b> {prod.status}
                  </li>
                </Col>
              </Row>
            </ul>
          </div>
        ))}
      </div>

      <h5>Miscellaneous:</h5>
      <div className="memoPage__miscs">
        <p>
          <b>Expected Quotation:</b> {misc?.quotation}
        </p>
        <p>
          <b>Advance Payment:</b> {misc?.payment}
        </p>
        <p>
          <b>Expected Delivery:</b> {misc?.date}
        </p>
      </div>
      <h5>Terms & Condition</h5>
      <div className="memoPage__terms">
        <ol>
          <li>Delivery is 11:00 am to 7:00 pm. (Monday to Saturday)</li>
          <li>
            Customer are requested to collect their product within 30 days from
            the delivery notification. <br /> Company shall not be liable for
            any loss, costs, damages, charges or expenses caused directly or
            indirectly by any delay.
          </li>
          <li>
            If the customer refuses to complete the job, then he/she has to pay
            the inspection charge 300 Rs under any condition.
          </li>
        </ol>
      </div>
      <div className="memoPage__signSec">
        <div className="memoPage__sign">
          <p>Customer Sign</p>
        </div>
        <div className="memoPage__sign">
          <p>Authorised Sign</p>
        </div>
      </div>
      {/* <div className="memoPage__buttons">
        <Link to={`/cfs/edit/${customer_num}/${bill_num}`}>
          <button>Update</button>
        </Link>
        <Link target="_blank" to={`/bill/${customer_num}/${bill_num}`}>
          <button>Generate Bill</button>
        </Link>
      </div> */}
    </div>
  );
}

export default MemoPage;
