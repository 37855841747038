import React, { useContext, useEffect, useState } from "react";
import "./Styles/Form.css";
import Loader from "react-loader-spinner";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { UserContext } from "../Context";
import { db } from "../firebase";
import { Alert, Col, Row, Button } from "react-bootstrap";

function Form() {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [product, setProduct] = useState([{}]);
  const [misc, setMisc] = useState({});
  const [jobData, setJobData] = useState({});
  const [id, setId] = useState({});
  const [prodList, setProdList] = useState([]);
  const [msg, setMsg] = useState("");

  const history = useHistory();
  const { form, customerID, billID } = useParams();

  const handleChanhge = ({ e, state, setState }) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
    });
  };

  // <== For product ==>>
  const handleProductChange = (e, i) => {
    const list = [...product];
    list[i][e.target.id] = e.target.value;
    setProduct(list);
  };

  const handelAddProduct = (e) => {
    e.preventDefault();
    setProduct([...product, {}]);
  };

  const handleRemoveInput = (i) => {
    const list = [...product];
    list.splice(i, 1);
    setProduct(list);
  };

  // <== End for product ==>

  const handleSearch = async (e) => {
    e.preventDefault();

    if (!customer.number) return;

    const cusData = await db.collection("customers").doc(customer.number).get();

    if (cusData.data()) {
      setCustomer(cusData.data());
    } else {
      setCustomer({ number: customer.number });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      Object.entries(customer).length >= 4 &&
      Object.entries(misc).length >= 3 &&
      Object.entries(product[0]).length >= 9
    ) {
      setMsg("");
      const time = Date.now().toString();

      //Create job
      product.map(async (prod) => {
        const time = Date.now().toString();
        // const timeNum = time.substring(6, 12);
        // const generateNum = Math.floor(
        //   (Math.random() + 1) * parseInt(timeNum)
        // ).toString();
        // const randomNum = generateNum.substring(0, 5);

        const lastJobIdRef = db.collection("jobid").doc("8888888888");
        const getJobId = await lastJobIdRef.get();
        const lastJobId = getJobId.data();

        const newId = ("000000" + (parseInt(lastJobId.jobId) + 1)).slice(-6);

        const billNo = `CFS${newId}`;

        lastJobId.jobId = newId;

        await lastJobIdRef.update(lastJobId);

        if (form !== "edit") {
          prod.billNo = billNo;
          prod.cusName = customer.name;
          prod.cusNum = customer.number;
        }

        await db
          .collection("jobs")
          .doc(form === "edit" ? prod.billNo : billNo)
          .set({
            product: prod,
            jobID: form === "edit" ? prod.billNo : billNo,
            timestamp: form === "edit" ? id.time : time,
          })
          .catch((err) => setMsg(err.message));
      });

      // misc ref
      const miscRef = db
        .collection("misc")
        .doc(form === "edit" ? id.time : time);

      //Create misc

      await miscRef
        .set({
          customerName: customer.name,
          customerNum: customer.number,
          quotation: misc.quotation,
          payment: misc.payment,
          due: misc.due,
          expense: context.user?.whoAmI !== "admin" ? "0" : misc.expense,
          timestamp: form === "edit" ? id.time : time,
        })
        .catch((err) => setMsg(err.message));

      // Customer Ref
      const customerRef = db.collection("customers").doc(customer.number);
      //Create customer
      await customerRef
        .set({
          name: customer.name,
          number: customer.number,
          email: customer.email,
          address: customer.address,
          timestamp: form === "edit" ? id.time : time,
        })
        .catch((err) => setMsg(err.message));

      //Generate Bill
      await customerRef
        .collection("bills")
        .doc(form === "edit" ? id.time : time)
        .set({
          jobData: jobData,
          timestamp: form === "edit" ? id.time : time,
          // billNo: id.billIdNo,
        })
        .catch((err) => setMsg(err.message));

      setJobData({});

      setLoading(false);
      history.push(
        `/customer/${customer.number}/${form === "edit" ? id.time : time}`
      );
    } else {
      setMsg("Fields can't be empty!");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (form === "edit" && billID) {
      setLoading(true);
      const getData = async () => {
        const billAccess = await db
          .collection("customers")
          .doc(customerID)
          .collection("bills")
          .doc(billID)
          .get();

        if (!billAccess.exists) {
          setMsg("Bill Doesn't exist!");
          return;
        }
        const billData = billAccess.data();
        const myData = billData.jobData;

        setCustomer(myData?.customer);
        setProdList(myData?.product);
        setMisc(myData?.misc);
        setId({
          time: billData.timestamp,
          billNo: myData?.product?.billNo,
        });
      };
      getData();
      setLoading(false);
    }
  }, [form, billID, customerID]);

  useEffect(() => {
    if (form === "edit" && billID) {
      setLoading(true);
      prodList.forEach((prod) => {
        setProduct([]);
        const job = async () => {
          const data = await db
            .collection("jobs")
            .where("jobID", "==", `${prod.billNo}`)
            .get();

          if (data.empty) {
            setMsg("Job data doesn't exist!");
            return;
          } else {
            data.forEach((doc) => {
              setProduct((product) => [...product, doc.data().product]);
              // console.log(doc.data().product);
            });
          }
        };
        job();
      });
      setLoading(false);
    }
  }, [prodList, billID, form]);

  // console.log(`Prod List >>`, prodList);
  // console.log(`product >> `, product);

  // generate due amount

  useEffect(() => {
    const generateDue = parseInt(misc.quotation - misc.payment);

    setMisc((miscs) => ({ ...miscs, due: generateDue }));
  }, [misc.quotation, misc.payment]);

  // console.log(misc);

  useEffect(() => {
    setLoading(true);
    setJobData({
      customer: customer,
      product: product,
      misc: misc,
    });
    setLoading(false);
  }, [customer, product, misc]);

  // console.log(prodList);

  // console.log(jobData);

  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "unset";
  }, [loading]);

  //set User
  if (!context.user?.uid || context.user?.whoAmI === "eng") {
    return <Redirect to="/signin" />;
  }

  // TODO: FORM >>>

  return (
    <>
      {loading && (
        <div className="loading">
          <Loader
            visible={loading}
            type="BallTriangle"
            color="#ffffff"
            height={80}
            width={80}
          />
        </div>
      )}
      <div className="form__Container">
        <div className="form">
          <h1>CRM FORM</h1>
          <div className="form__section">
            <form>
              <h3>Customer Details: </h3>
              <Row className="form__customerSec">
                <Col className="form__displayColumn">
                  <label className="app__InputSec" htmlFor="name">
                    <p>Name</p>
                    <input
                      maxLength="32"
                      type="text"
                      id="name"
                      onChange={(e) =>
                        handleChanhge({
                          e: e,
                          state: customer,
                          setState: setCustomer,
                        })
                      }
                      value={customer.name || ""}
                    />
                  </label>
                  <label className="app__InputSec" htmlFor="number">
                    <p>Number</p>
                    <input
                      type="text"
                      maxLength="10"
                      id="number"
                      onChange={(e) =>
                        handleChanhge({
                          e: e,
                          state: customer,
                          setState: setCustomer,
                        })
                      }
                      value={customer.number || ""}
                    />
                    <Button
                      className="searchCustomerBtn"
                      variant="info"
                      size="sm"
                      onClick={(e) => handleSearch(e)}
                    >
                      O
                    </Button>
                  </label>
                </Col>
                <Col className="form__displayColumn">
                  <label className="app__InputSec" htmlFor="email">
                    <p>Email ID</p>
                    <input
                      maxLength="32"
                      type="text"
                      id="email"
                      onChange={(e) =>
                        handleChanhge({
                          e: e,
                          state: customer,
                          setState: setCustomer,
                        })
                      }
                      value={customer.email || ""}
                    />
                  </label>
                  <label className="app__InputSec" htmlFor="address">
                    <p>Address</p>
                    <input
                      maxLength="100"
                      type="text"
                      id="address"
                      onChange={(e) =>
                        handleChanhge({
                          e: e,
                          state: customer,
                          setState: setCustomer,
                        })
                      }
                      value={customer.address || ""}
                    />
                  </label>
                </Col>
              </Row>
              <h3>Product Details: </h3>
              <div className="form__productSec">
                {product.map((prod, i) => (
                  <Row key={i} className="form__product">
                    <Col className="form__displayColumn">
                      <label className="app__InputSec" htmlFor="product">
                        <p>Product </p>
                      </label>
                      <select
                        id="product"
                        value={prod.product}
                        onChange={(e) => handleProductChange(e, i)}
                      >
                        <option value="">Select</option>
                        <option value="Laptop">Laptop</option>
                        <option value="Motherboard">Motherboard</option>
                        <option value="Desktop">Desktop</option>
                        <option value="Monitor">Monitor</option>
                        <option value="Tab">Tab</option>
                        <option value="V Card">V Card</option>
                        <option value="HDD">HDD</option>
                        <option value="Printer">Printer</option>
                        <option value="DVR">DVR</option>
                        <option value="L. MoBo">L. MoBo</option>
                        <option value="All in One">All in One</option>
                        <option value="Other">Other</option>
                      </select>
                      <label className="app__InputSec" htmlFor="brand">
                        <p>Brand</p>
                        <input
                          maxLength="32"
                          type="text"
                          id="brand"
                          onChange={(e) => handleProductChange(e, i)}
                          value={prod.brand || ""}
                        />
                      </label>
                      <label className="app__InputSec" htmlFor="model">
                        <p>Model No</p>
                        <input
                          maxLength="50"
                          type="text"
                          id="model"
                          onChange={(e) => handleProductChange(e, i)}
                          value={prod.model || ""}
                        />
                      </label>
                      <label className="app__InputSec" htmlFor="serial">
                        <p>Serial No</p>
                        <input
                          maxLength="50"
                          type="text"
                          id="serial"
                          onChange={(e) => handleProductChange(e, i)}
                          value={prod.serial || ""}
                        />
                      </label>
                      <label className="app__InputSec" htmlFor="problem">
                        <p>Problem</p>
                        <input
                          maxLength="200"
                          type="text"
                          id="problem"
                          onChange={(e) => handleProductChange(e, i)}
                          value={prod.problem || ""}
                        />
                      </label>
                    </Col>
                    <Col className="form__displayColumn">
                      <label className="app__InputSec" htmlFor="accessories">
                        <p>Other Accs</p>
                        <input
                          maxLength="100"
                          type="text"
                          id="accessories"
                          onChange={(e) => handleProductChange(e, i)}
                          value={prod.accessories || ""}
                        />
                      </label>
                      <label className="app__InputSec" htmlFor="remark">
                        <p>Remark</p>
                        <input
                          maxLength="200"
                          type="text"
                          id="remark"
                          onChange={(e) => handleProductChange(e, i)}
                          value={prod.remark || ""}
                        />
                      </label>

                      {/* <label className="app__InputSec" htmlFor="spareRequired">
                        <p>Spare Required</p>
                        <input
                          maxLength="50"
                          type="text"
                          id="spareRequired"
                          onChange={(e) => handleProductChange(e, i)}
                          value={prod.spareRequired || ""}
                        />
                      </label> */}

                      <label className="app__InputSec" htmlFor="spareReplaced">
                        <p>Spare Replaced</p>
                        <input
                          maxLength="50"
                          type="text"
                          id="spareReplaced"
                          onChange={(e) => handleProductChange(e, i)}
                          value={prod.spareReplaced || ""}
                        />
                      </label>
                      <label className="app__InputSec" htmlFor="status">
                        <p>Status</p>
                      </label>
                      <select
                        id="status"
                        value={prod.status}
                        onChange={(e) => handleProductChange(e, i)}
                      >
                        <option value="">Select</option>
                        <option value="Received">Received</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Repaired">Repaired</option>
                        <option value="Not Repairable">Not Repairable</option>
                        <option value="N.R. Delivered">N.R. Delivered</option>
                        <option value="Delivered">Delivered</option>
                      </select>
                    </Col>
                    <Col className="form__productAddBtn" sm={12}>
                      {form !== "edit" && product.length !== 1 && (
                        <button onClick={() => handleRemoveInput(i)}>
                          Remove product
                        </button>
                      )}
                    </Col>
                  </Row>
                ))}

                {form !== "edit" && (
                  <Row>
                    <Col className="form__productAddBtn" sm={12}>
                      <button onClick={handelAddProduct}>Add Product</button>
                    </Col>
                  </Row>
                )}
              </div>
              <h3>Miscellaneous: </h3>
              <Row className="form__misc">
                <Col className="form__displayColumn">
                  <label className="app__InputSec" htmlFor="quotation">
                    <p>Quotation</p>
                    <input
                      maxLength="10"
                      type="number"
                      id="quotation"
                      onChange={(e) =>
                        handleChanhge({
                          e: e,
                          state: misc,
                          setState: setMisc,
                        })
                      }
                      value={misc.quotation || ""}
                    />
                  </label>
                  <label className="app__InputSec" htmlFor="payment">
                    <p>Payment</p>
                    <input
                      maxLength="10"
                      type="number"
                      id="payment"
                      onChange={(e) =>
                        handleChanhge({
                          e: e,
                          state: misc,
                          setState: setMisc,
                        })
                      }
                      value={misc.payment || ""}
                    />
                  </label>
                </Col>
                <Col className="form__displayColumn">
                  <label className="app__InputSec" htmlFor="expense">
                    <p>Expense</p>
                    <input
                      maxLength="10"
                      type="number"
                      id="expense"
                      onChange={(e) =>
                        handleChanhge({
                          e: e,
                          state: misc,
                          setState: setMisc,
                        })
                      }
                      value={misc.expense || ""}
                    />
                  </label>
                  <label className="app__InputSec" htmlFor="date">
                    <p>Delivery Date</p>
                    <input
                      type="date"
                      value={misc.date || ""}
                      id="date"
                      onChange={(e) =>
                        handleChanhge({
                          e: e,
                          state: misc,
                          setState: setMisc,
                        })
                      }
                    />
                  </label>
                </Col>
              </Row>
              {msg && (
                <Row>
                  <Alert className="text-center form__alert" variant="danger">
                    <p>{msg}</p>
                  </Alert>
                </Row>
              )}
              <Row>
                <Col sm={12} className="form__submitBtn">
                  <button onClick={handleSubmit}>
                    {form !== "edit" ? "Generate Job" : "Update Job"}
                  </button>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;
