import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { Redirect, useParams } from "react-router-dom";
import { UserContext } from "../Context";
import { db } from "../firebase";
import "./Styles/ExpensePage.css";

function ExpensePage() {
  const { time_stamp } = useParams();
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [expenseList, setExpenseList] = useState([]);

  useEffect(() => {
    setLoading(true);
    let mount = true;
    const job = async () => {
      const data = await db
        .collection("expenses")
        .where("date", ">=", `${time_stamp}`)
        .orderBy("date", "desc")
        .get();

      if (data.empty) {
        return;
      } else {
        data.forEach((doc) => {
          if (mount) {
            setExpenseList((expense) => [...expense, doc.data()]);
          }
        });
      }
    };

    job();
    setLoading(false);
    return () => (mount = false);
  }, [time_stamp]);

  //set User
  if (!context.user?.uid || context.user?.whoAmI !== "admin") {
    return <Redirect to="/signin" />;
  }
  return (
    <>
      {loading && (
        <div className="loading">
          <Loader
            visible={loading}
            type="BallTriangle"
            color="#ffffff"
            height={80}
            width={80}
          />
        </div>
      )}
      <Container className="expense">
        <h1>Expenses</h1>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {expenseList.map((expen) => {
              const dateRef = new Date(parseInt(expen.date));
              const date = dateRef.toLocaleDateString();

              return (
                <tr key={expen.timestamp}>
                  <td>{date}</td>
                  <td>{expen.amount}</td>
                  <td>{expen.remark}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Container>
    </>
  );
}

export default ExpensePage;
