import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import "./Styles/EngPage.css";
import { db } from "../firebase";
import { Redirect } from "react-router-dom";
import { UserContext } from "../Context";
import Loader from "react-loader-spinner";

function EngPage() {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [jobProduct, setJobProduct] = useState();
  const [msg, setMsg] = useState("");
  const [toggle, setToggle] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchValue === "") {
      setMsg("Can't search empty");
      return;
    }
    setLoading(true);

    const job = async () => {
      const data = await db
        .collection("jobs")
        .where("jobID", "==", `CFS${searchValue}`)
        .get();

      if (data.empty) {
        setJobProduct();
        setMsg("No Job found!");
        return;
      } else {
        setMsg("");
        data.forEach((doc) => {
          setJobProduct(doc.data().product);
          // console.log(doc.data());
        });
      }
    };

    job();
    setLoading(false);
  };

  const handleChange = (e) => {
    setJobProduct({
      ...jobProduct,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setToggle(false);

    await db
      .doc(`/jobs/CFS${searchValue}`)
      .update({ product: jobProduct })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "unset";
  }, [loading]);

  //set User
  if (!context.user?.uid) {
    return <Redirect to="/signin" />;
  }

  // console.log(jobProduct);
  // const jobProduct = jobData;

  return (
    <Container fluid className="engPage">
      {loading && (
        <div className="loading">
          <Loader
            visible={loading}
            type="BallTriangle"
            color="#ffffff"
            height={80}
            width={80}
          />
        </div>
      )}
      <Row>
        <Col md={6} className="offset-md-3 text-center engPage__SearchBox">
          <form onSubmit={handleSearch}>
            <input
              id="jobSearch"
              type="search"
              maxLength="6"
              placeholder="Only Digits"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <button type="submit" onClick={handleSearch}>
              Find
            </button>
          </form>
        </Col>
      </Row>
      {msg !== "" && (
        <Row>
          <Alert
            className="text-center  mx-5 my-2 engPage__alert"
            variant="danger"
          >
            <p color="danger">{msg}</p>
          </Alert>
        </Row>
      )}
      <Row>
        {jobProduct && (
          <Col md={6} className="offset-md-3 engPage__jobSec">
            <h3>JOB ID : {jobProduct.billNo}</h3>
            <ul>
              <li>
                <b>Contact : </b> {jobProduct.cusNum}
              </li>
              <li>
                <b>Product : </b> {jobProduct.product}
              </li>
              <li>
                <b>Brand : </b> {jobProduct.brand}
              </li>
              <li>
                <b>Model No : </b> {jobProduct.serial}
              </li>
              <li>
                <b>Other Accs : </b> {jobProduct.accessories}
              </li>
              <li>
                <b>Problem : </b> {jobProduct.problem}
              </li>
              <li>
                <b>Spare Replaced : </b> {jobProduct.spareReplaced}
              </li>
              <li>
                <b>Remarks : </b>
                {jobProduct.remark}
              </li>
              <li>
                <b>Status : </b>
                {jobProduct.status}
              </li>
            </ul>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setToggle(!toggle);
              }}
              block
              variant="dark"
            >
              Update
            </Button>
          </Col>
        )}
      </Row>
      {jobProduct && toggle && (
        <div className="engPage__modalContainer">
          <div className="engPage__formModal">
            <div className="engPage__input">
              <label htmlFor="status">
                <p>Status :</p>
              </label>
              <select
                id="status"
                value={jobProduct.status}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="Received">Received</option>
                <option value="In Progress">In Progress</option>
                <option value="Repaired">Repaired</option>
                <option value="Not Repairable">Not Repairable</option>
                <option vlue="Delivered">Delivered</option>
              </select>
              <label htmlFor="product">
                <p>Product : </p>
              </label>
              <select id="product" readOnly value={jobProduct.product}>
                <option value="">Select</option>
                <option value="Laptop">Laptop</option>
                <option value="Motherboard">Motherboard</option>
                <option value="Desktop">Desktop</option>
                <option value="Monitor">Monitor</option>
                <option value="Tab">Tab</option>
                <option value="V Card">V Card</option>
                <option value="HDD">HDD</option>
                <option value="Printer">Printer</option>
                <option value="DVR">DVR</option>
                <option value="L. MoBo">L. MoBo</option>
                <option value="All in One">All in One</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="engPage__input">
              <label htmlFor="brand">
                <p>Brand : </p>
                <input
                  maxLength="32"
                  type="text"
                  id="brand"
                  readOnly
                  value={jobProduct.brand || ""}
                />
              </label>
            </div>
            <div className="engPage__input">
              <label htmlFor="model">
                <p>Model No : </p>
                <input
                  maxLength="50"
                  type="text"
                  id="model"
                  readOnly
                  value={jobProduct.model || ""}
                />
              </label>
            </div>
            <div className="engPage__input">
              <label htmlFor="serial">
                <p>Serial No : </p>
                <input
                  maxLength="50"
                  type="text"
                  id="serial"
                  readOnly
                  value={jobProduct.serial || ""}
                />
              </label>
            </div>
            <div className="engPage__input">
              <label htmlFor="problem">
                <p>Problem : </p>
                <input
                  maxLength="200"
                  type="text"
                  id="problem"
                  readOnly
                  value={jobProduct.problem || ""}
                />
              </label>
            </div>
            <div className="engPage__input">
              <label htmlFor="accessories">
                <p>Other Accs : </p>
                <input
                  maxLength="100"
                  type="text"
                  id="accessories"
                  readOnly
                  value={jobProduct.accessories || ""}
                />
              </label>
            </div>
            <div className="engPage__input">
              <label htmlFor="remark">
                <p>Remarks : </p>
                <input
                  maxLength="200"
                  type="text"
                  id="remark"
                  onChange={handleChange}
                  value={jobProduct.remark || ""}
                />
              </label>
            </div>

            {/* <div className="engPage__input">
              <label htmlFor="spareRequired">
                <p>Spare Required : </p>
                <input
                  maxLength="50"
                  type="text"
                  id="spareRequired"
                  onChange={handleChange}
                  value={jobProduct.spareRequired || ""}
                />
              </label>
            </div> */}

            <div className="engPage__input">
              <label htmlFor="spareReplaced">
                <p>Spare Replaced : </p>
                <input
                  maxLength="50"
                  type="text"
                  id="spareReplaced"
                  onChange={handleChange}
                  value={jobProduct.spareReplaced || ""}
                />
              </label>
            </div>

            <div className="engPage__buttons">
              <Button variant="success" onClick={handleSubmit}>
                Update
              </Button>
              <Button
                variant="outline-dark"
                onClick={(e) => {
                  e.preventDefault();
                  setToggle(false);
                }}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
}

export default EngPage;
