import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./Styles/StatusProduct.css";

function StatusProduct({ productData }) {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="statusProduct">
      <div className="statusProduct__header" onClick={() => setToggle(!toggle)}>
        <h5>Product : {productData.product}</h5>
        <h5>Job ID : {productData.billNo}</h5>
      </div>
      {productData && toggle && (
        <Row
          onClick={() => setToggle(!toggle)}
          className="statusProduct__hidden"
        >
          <Col sm={6}>
            <p>
              <b>Brand :</b> {productData.brand}
            </p>
            <p>
              <b>Model No : </b>
              {productData.model}
            </p>
            <p>
              <b>Serial No : </b>
              {productData.serial}
            </p>
            <p>
              <b>Customer Name : </b>
              {productData.cusName}
            </p>
            <p>
              <b>Customer Contact : </b>
              {productData.cusNum}
            </p>
          </Col>
          <Col sm={6}>
            <p>
              <b>Other Accs : </b>
              {productData.accessories}
            </p>
            <p>
              <b>Problem : </b>
              {productData.problem}
            </p>
            <p>
              <b>Remarks : </b>
              {productData.remark}
            </p>

            {/* <p>
              <b>Spare Required : </b>
              {productData.spareRequired}
            </p> */}

            <p>
              <b>Spare Replaced : </b>
              {productData.spareReplaced}
            </p>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default StatusProduct;
