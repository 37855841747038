import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

//===>> Production

const firebaseConfig = {
  apiKey: "AIzaSyC7u7vbXr5rZzJSXOifmDk5evLU2xm4Uto",
  authDomain: "cfs-crm.firebaseapp.com",
  databaseURL: "https://cfs-crm.firebaseio.com",
  projectId: "cfs-crm",
  storageBucket: "cfs-crm.appspot.com",
  messagingSenderId: "870190604355",
  appId: "1:870190604355:web:025813a6f78bd2a6dcb4f6",
  measurementId: "G-XZXSB4FT4L",
};

//===>> Local

// const firebaseConfig = {
//   apiKey: "AIzaSyDmzdPJUZEIpT2PZOEU6KfRqkOqhNuh-1s",
//   authDomain: "crm-test-d8931.firebaseapp.com",
//   databaseURL: "https://crm-test-d8931.firebaseio.com",
//   projectId: "crm-test-d8931",
//   storageBucket: "crm-test-d8931.appspot.com",
//   messagingSenderId: "940530289060",
//   appId: "1:940530289060:web:929f0e173f7bf9e5681931",
// };

const fireApp = firebase.initializeApp(firebaseConfig);

export const db = fireApp.firestore();
export const auth = fireApp.auth();
