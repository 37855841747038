import React, { useContext, useEffect, useState } from "react";
import "./Styles/StatusPage.css";
import Loader from "react-loader-spinner";
import { Redirect, useParams } from "react-router-dom";
import { UserContext } from "../Context";
import { db } from "../firebase";
import StatusProduct from "./StatusProduct";

function StatusPage() {
  const context = useContext(UserContext);
  const { statusID, timestamp } = useParams();
  const [loading, setLoading] = useState(false);
  const [queryStatus, setQueryStatus] = useState("");
  const [product, setProduct] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (statusID === "received") {
      setQueryStatus("Received");
    } else if (statusID === "inProgress") {
      setQueryStatus("In Progress");
    } else if (statusID === "repaired") {
      setQueryStatus("Repaired");
    } else if (statusID === "notRepairable") {
      setQueryStatus("Not Repairable");
    } else if (statusID === "delivered") {
      setQueryStatus("Delivered");
    } else if (statusID === "nr-delivered") {
      setQueryStatus("N.R. Delivered");
    } else {
      return;
    }
    setLoading(false);
  }, [statusID]);

  useEffect(() => {
    setLoading(true);
    const job = async () => {
      const data = await db
        .collection("jobs")
        .where("timestamp", ">=", `${timestamp}`)
        .where("product.status", "==", `${queryStatus}`)
        .get();

      if (data.empty) {
        return;
      } else {
        data.forEach((doc) => {
          setProduct((product) => [...product, doc.data().product]);
        });
      }
    };

    job();
    setLoading(false);
  }, [queryStatus, timestamp]);
  // console.log(product);

  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "unset";
  }, [loading]);

  //set User
  if (!context.user?.uid || context.user?.whoAmI !== "admin") {
    return <Redirect to="/signin" />;
  }

  return (
    <div className="statusPage">
      {loading && (
        <div className="loading">
          <Loader
            visible={loading}
            type="BallTriangle"
            color="#ffffff"
            height={80}
            width={80}
          />
        </div>
      )}
      <h1>Job Status : {queryStatus}</h1>
      <div className="statusPage__list">
        {product.map((prod) => (
          <StatusProduct key={`${prod.billNo}`} productData={prod} />
        ))}
      </div>
    </div>
  );
}

export default StatusPage;
