import React, { useEffect, useState } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import MemoPage from "./Components/MemoPage";
import BillList from "./Components/BillList";
import EngPage from "./Components/EngPage";
import Form from "./Components/Form";
import Signin from "./Components/Signin";
import BillPage from "./Components/BillPage";
import { auth } from "./firebase";
import Dashboard from "./Components/Dashboard";
import NavBar from "./Components/NavBar";
import { UserContext } from "./Context";
import StatusPage from "./Components/StatusPage";
import Loader from "react-loader-spinner";
import ExpensePage from "./Components/ExpensePage";
import DuePage from "./Components/DuePage";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  //set User
  useEffect(() => {
    // setLoading(true);
    const localUser = sessionStorage.getItem("localUser");
    if (!localUser) {
      const mount = auth.onAuthStateChanged((userAuth) => {
        if (userAuth) {
          if (userAuth.email === "admin@cfs.com") {
            setUser({
              email: userAuth.email,
              uid: userAuth.uid,
              whoAmI: "admin",
            });
          } else if (userAuth.email === "frontdesk@cfs.com") {
            setUser({
              email: userAuth.email,
              uid: userAuth.uid,
              whoAmI: "frontdesk",
            });
          } else if (userAuth.email === "eng@cfs.com") {
            setUser({
              email: userAuth.email,
              uid: userAuth.uid,
              whoAmI: "eng",
            });
          }
        } else {
          setUser(null);
          sessionStorage.clear();
        }
      });

      setLoading(false);
      return () => mount();
    } else {
      setUser(JSON.parse(localUser));
    }
  }, []);

  useEffect(() => {
    if (user !== null) {
      sessionStorage.setItem("localUser", JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "unset";
  }, [loading]);

  return (
    <div>
      <Switch>
        <UserContext.Provider value={{ user, setUser }}>
          {loading && (
            <div className="loading">
              <Loader
                visible={loading}
                type="BallTriangle"
                color="#ffffff"
                height={80}
                width={80}
              />
            </div>
          )}
          <Route exact path="/customer/:customer_num/:bill_num">
            <MemoPage />
          </Route>
          <Route exact path="/bill/:customer_num/:bill_num">
            <BillPage />
          </Route>
          <Route exact path="/customer/:customer_num">
            <NavBar />
            <BillList />
          </Route>
          <Route exact path="/expense/:time_stamp">
            <NavBar />
            <ExpensePage />
          </Route>
          <Route exact path="/status/:statusID/:timestamp">
            <NavBar />
            <StatusPage />
          </Route>
          <Route exact path="/due-list/:timestamp">
            <NavBar />
            <DuePage />
          </Route>
          <Route exact path="/engineer">
            <NavBar />
            <EngPage />
          </Route>
          <Route exact path="/cfs/:form/:customerID/:billID">
            <NavBar />
            <Form />
          </Route>
          <Route exact path="/dashboard">
            <NavBar />
            <Dashboard />
          </Route>
          <Route exact path="/signin">
            <Signin />
          </Route>
          <Route exact path="/">
            <div className="app__home">
              <h1>COMPUFACT SYSTEMS</h1>
              <h3>Coming Soon</h3>
            </div>
          </Route>
        </UserContext.Provider>
      </Switch>
    </div>
  );
}

export default withRouter(App);
