import React, { useContext, useEffect, useState } from "react";
import "./Styles/StatusPage.css";
import Loader from "react-loader-spinner";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { UserContext } from "../Context";
import { db } from "../firebase";
import "./Styles/BillList.css";

function DuePage() {
  const context = useContext(UserContext);
  const { timestamp } = useParams();
  const [loading, setLoading] = useState(false);
  const [misc, setMisc] = useState([]);

  const history = useHistory();
  const handleLink = (cusNum) => {
    history.push(`/customer/${cusNum}`);
  };

  useEffect(() => {
    let mount = true;
    setLoading(true);
    const misc = async () => {
      const data = await db
        .collection("misc")
        .where("timestamp", ">=", `${timestamp}`)
        // .where("due", ">", "0")
        .get();

      if (data.empty) {
        return;
      } else {
        data.forEach((doc) => {
          if (mount && doc.data().due > "0") {
            setMisc((mis) => [...mis, doc.data()]);
          }
        });
      }
    };

    misc();
    setLoading(false);
    return () => (mount = false);
  }, [timestamp]);

  console.log(misc);

  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "unset";
  }, [loading]);

  //set User
  if (!context.user?.uid || context.user?.whoAmI !== "admin") {
    return <Redirect to="/signin" />;
  }

  return (
    <div className="statusPage">
      {loading && (
        <div className="loading">
          <Loader
            visible={loading}
            type="BallTriangle"
            color="#ffffff"
            height={80}
            width={80}
          />
        </div>
      )}
      <h1>Payment Due</h1>
      <div className="container statusPage__list">
        <div className="billList__container">
          <table>
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>Customer Number</th>
                <th>Quotation</th>
                <th>Paid Amount</th>
                <th>Due Amount</th>
              </tr>
            </thead>
            <tbody>
              {misc &&
                misc.map((mis) => {
                  return (
                    <tr
                      key={mis.timestamp}
                      className="billList__bill"
                      onClick={() => handleLink(mis.customerNum)}
                    >
                      <td>{mis.customerName ? mis.customerName : "Unknown"}</td>
                      <td>{mis.customerNum ? mis.customerNum : "Unknown"}</td>
                      <td>{mis.quotation}</td>
                      <td>{mis.payment}</td>
                      <td>{mis.due}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DuePage;
