import React, { useContext, useEffect, useState } from "react";
import "./Styles/BillList.css";
import Loader from "react-loader-spinner";
import { Redirect, useParams, Link } from "react-router-dom";
import { UserContext } from "../Context";
import { db } from "../firebase";
import { Container } from "react-bootstrap";

function BillList() {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const { customer_num } = useParams();
  const [bills, setBills] = useState([]);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    let mount = true;
    setLoading(true);

    const checkDoc = async () => {
      const customerRef = db.collection("customers").doc(customer_num);

      const doc = await customerRef.get();

      if (!doc.exists) {
        return setMsg("Customer doesn't exist!");
      } else {
        setMsg("");
      }
    };

    checkDoc();

    const customerRef = db.collection("customers").doc(customer_num);

    customerRef
      .collection("bills")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        if (mount) {
          setBills(snapshot.docs.map((doc) => doc.data()));
          setLoading(false);
        }
      });

    return () => (mount = false);
  }, [customer_num]);

  useEffect(() => {
    let mount = true;

    if (mount) {
      document.body.style.overflow = loading ? "hidden" : "unset";
    }

    return () => (mount = false);
  }, [loading]);

  // const history = useHistory();
  // const handleLink = (billID) => {
  //   history.push(`/customer/${customer_num}/${billID}`);
  // };

  //set User
  if (!context.user?.uid || context.user?.whoAmI === "eng") {
    return <Redirect to="/signin" />;
  }

  // console.log(bills);

  return (
    <>
      {loading && (
        <div className="loading">
          <Loader
            visible={loading}
            type="BallTriangle"
            color="#ffffff"
            height={80}
            width={80}
          />
        </div>
      )}
      <Container className="billList">
        <h1>Customer: {customer_num}</h1>
        {msg !== "" && <h2>{msg}</h2>}

        {msg === "" && (
          <div className="billList__container">
            <table>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Job ID</th>
                  <th>Quotaion</th>
                  <th>Paid</th>
                  <th>Due</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {bills &&
                  bills.map((bill) => {
                    const misc = bill.jobData?.misc;
                    const customer = bill.jobData?.customer;
                    const product = bill.jobData?.product;
                    // const time = new Date(parseInt(bill.timestamp));
                    // const date = time.toLocaleDateString();
                    return (
                      <tr className="billList__bill" key={bill.timestamp}>
                        <td>{customer?.name}</td>
                        <td>{product[0]?.billNo}</td>
                        <td>{misc?.quotation}</td>
                        <td>{misc?.payment}</td>
                        <td>{misc?.due ? misc?.due : "0"}</td>
                        <td>{product[0]?.status}</td>
                        <td>
                          <div className="billList__buttons">
                            <Link
                              to={`/cfs/edit/${customer?.number}/${bill.timestamp}`}
                            >
                              <button>Update</button>
                            </Link>
                            <Link
                              target="_blank"
                              to={`/customer/${customer?.number}/${bill.timestamp}`}
                            >
                              <button>Memo</button>
                            </Link>
                            <Link
                              target="_blank"
                              to={`/bill/${customer?.number}/${bill.timestamp}`}
                            >
                              <button>Bill</button>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </Container>
    </>
  );
}

export default BillList;
