import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import { UserContext } from "../Context";
import { auth, db } from "../firebase";
import logo from "../Assets/logo.png";
import "./Styles/NavBar.css";

function NavBar() {
  const context = useContext(UserContext);
  const [searchValue, setSearchValue] = useState("");
  const [stockValue, setStockValue] = useState("");
  const [expense, setExpense] = useState({});
  const [stockToggle, setStockToggle] = useState(false);
  const [expenseToggle, setExpenseToggle] = useState(false);
  const [msg, setMsg] = useState("");

  const history = useHistory();

  const findCustomer = (e) => {
    e.preventDefault();
    if (searchValue !== "") {
      history.push(`/customer/${searchValue}`);
      setSearchValue("");
    }
  };

  // Add Stock
  const addStock = async (e) => {
    if (stockValue === "") {
      setMsg("Can't add empty");
      return;
    }
    e.preventDefault();
    setMsg("");
    await db
      .collection("stocks")
      .doc(stockValue)
      .set({ stock: stockValue })
      .then((data) => setMsg("Stock Added!"))
      .catch((err) => setMsg(err));

    setStockValue("");
  };

  // Find Stock
  const findStock = async (e) => {
    e.preventDefault();
    if (stockValue === "") {
      setMsg("Can't add empty");
      return;
    }
    setMsg("");
    const stockCheck = await db
      .collection("stocks")
      .where("stock", "==", `${stockValue}`)
      .get();

    if (!stockCheck.empty) {
      setMsg("Stock is available");
      return;
    } else {
      setMsg("Stock not Found!");
    }

    setStockValue("");
  };

  //handle Expense
  const handleExpChange = (e) => {
    const { id, value } = e.target;
    setExpense({
      ...expense,
      [id]: value,
    });
  };

  const handleExpAdd = async (e) => {
    e.preventDefault();
    if (Object.entries(expense).length < 3) {
      setMsg("Input can't be empty!");
      return;
    }
    setMsg("");
    const timestamp = Date.now().toString();

    const newDate = Date.parse(expense.date).toString();

    await db
      .collection("expenses")
      .doc(`${timestamp}`)
      .set({
        date: newDate,
        amount: expense.amount,
        remark: expense.remark,
        timestamp: timestamp,
      })
      .then((data) => setMsg(""))
      .catch((err) => setMsg(err));
    setMsg("");
    setExpense({});
    setExpenseToggle(false);
  };

  useEffect(() => {
    document.body.style.overflowY = stockToggle ? "hidden" : "unset";
  }, [stockToggle, expenseToggle]);

  //set User
  const currentUser = context.user;
  if (!currentUser?.uid) {
    return <Redirect to="/signin" />;
  }
  const whoAmI = currentUser?.whoAmI;

  return (
    <>
      {expenseToggle && (
        <div className="navBar__stockContainer">
          <div className="navBar__stock">
            <span
              onClick={() => setExpenseToggle(false)}
              className="navBar__close"
            >
              X
            </span>
            <h3>Add Expenses</h3>
            <label htmlFor="amount">
              <p>Amount: </p>
              <input
                type="number"
                name="amount"
                id="amount"
                value={expense.amount || ""}
                onChange={handleExpChange}
              />
            </label>
            <label htmlFor="date">
              <p>Date: </p>
              <input
                type="date"
                name="date"
                id="date"
                value={expense.date || ""}
                onChange={handleExpChange}
              />
            </label>
            <label htmlFor="remark">
              <p>Remarks: </p>
              <textarea
                name="remark"
                id="remark"
                value={expense.remark || ""}
                onChange={handleExpChange}
              />
            </label>
            {msg && (
              <Row>
                <Alert className="text-center navBar__alert" variant="danger">
                  <p>{msg}</p>
                </Alert>
              </Row>
            )}
            <Button onClick={handleExpAdd}>ADD</Button>
          </div>
        </div>
      )}
      {stockToggle && (
        <div className="navBar__stockContainer">
          <div className="navBar__stock">
            <span
              onClick={() => setStockToggle(false)}
              className="navBar__close"
            >
              X
            </span>
            <h3>Stock</h3>
            <input
              value={stockValue}
              onChange={(e) => setStockValue(e.target.value.toUpperCase())}
              type="text"
            />
            {msg && (
              <Row>
                <Alert className="text-center navBar__alert" variant="success">
                  <p>{msg}</p>
                </Alert>
              </Row>
            )}
            {whoAmI === "admin" && (
              <Button onClick={addStock} variant="primary">
                Add
              </Button>
            )}
            <Button onClick={findStock} variant="success">
              Find
            </Button>
          </div>
        </div>
      )}
      <Row className="navBar">
        <Col className="navBar__left">
          <div className="navBar__logo">
            <img src={logo} alt="" />
          </div>
          <div className="navBar___list">
            {whoAmI === "admin" && (
              <Link to="/dashboard">
                <h5>Dashboard</h5>
              </Link>
            )}
            {whoAmI !== "eng" && (
              <Link to="/cfs/job/generate/form">
                <h5>Create</h5>
              </Link>
            )}
            <Link to="/engineer">
              <h5>Engineer</h5>
            </Link>
            <h5 className="navBar__link" onClick={() => setStockToggle(true)}>
              Stock
            </h5>
            <h5 className="navBar__link" onClick={() => setExpenseToggle(true)}>
              Expense
            </h5>
          </div>
        </Col>
        <Col className="navBar__right">
          {whoAmI !== "eng" && (
            <div className="navBar___searchBox">
              <form onSubmit={findCustomer}>
                <input
                  placeholder="Search Customer"
                  id="search"
                  type="search"
                  maxLength="10"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <button type="submit" onClick={findCustomer}>
                  Search
                </button>
              </form>
            </div>
          )}
          <div className="navBar__signOut">
            <button
              onClick={(e) => {
                e.preventDefault();
                auth.signOut();
                sessionStorage.clear();
                window.location.reload();
              }}
            >
              Sign Out
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default NavBar;
