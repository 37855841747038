import React, { useContext, useEffect, useState } from "react";
import { db } from "../firebase";
import { Col, Row } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { UserContext } from "../Context";
import Loader from "react-loader-spinner";
import { CSVLink } from "react-csv";
import "./Styles/Dashboard.css";

function Dashboard() {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [downloadToggle, setDownloadToggle] = useState(false);
  const [misc, setMisc] = useState([]);
  const [status, setStatus] = useState([]);
  const [offExp, setOffExp] = useState([]);
  const [paymentCount, setPaymentCount] = useState(0);
  const [quotCount, setQuotCount] = useState(0);
  const [expenses, setExpenses] = useState(0);
  const [offExpCount, setOffExpCount] = useState(0);
  const [received, setReceived] = useState(0);
  const [inProgress, setInProgress] = useState(0);
  const [repaired, setRepaired] = useState(0);
  const [notRepairable, setNotRepairable] = useState(0);
  const [delivered, setDelivered] = useState(0);
  const [nrDelivered, setNrDelivered] = useState(0);
  const [due, setDue] = useState(0);
  const [profit, setProfit] = useState(0);
  const [pickDate, setPickDate] = useState(new Date());
  const [queryDate, setQueryDate] = useState("");
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    setLoading(true);

    setQueryDate((new Date(pickDate).getTime() - 86400000).toString());

    setLoading(false);
  }, [pickDate]);

  useEffect(() => {
    setLoading(true);
    let mount = true;
    db.collection("misc")
      .where("timestamp", ">=", `${queryDate}`)
      .onSnapshot((snapshot) => {
        if (mount) {
          setMisc(snapshot.docs.map((doc) => doc.data()));
        }
      });

    db.collection("expenses")
      .where("date", ">=", `${queryDate}`)
      .onSnapshot((snapshot) => {
        if (mount) {
          setOffExp(snapshot.docs.map((doc) => doc.data().amount));
        }
      });

    db.collection("jobs")
      .where("timestamp", ">=", `${queryDate}`)
      .onSnapshot((snapshot) => {
        if (mount) {
          setStatus(
            snapshot.docs.map((doc) => {
              const status = doc.data().product.status;
              return status;
            })
          );
        }
      });

    setLoading(false);
    return () => (mount = false);
  }, [queryDate]);

  useEffect(() => {
    setLoading(true);
    setReceived(0);
    setInProgress(0);
    setRepaired(0);
    setNotRepairable(0);
    setDelivered(0);
    setNrDelivered(0);

    status.forEach((state) => {
      if (state === "Received") {
        setReceived((received) => received + 1);
      } else if (state === "In Progress") {
        setInProgress((inProgress) => inProgress + 1);
      } else if (state === "Repaired") {
        setRepaired((repaired) => repaired + 1);
      } else if (state === "Not Repairable") {
        setNotRepairable((notRepairable) => notRepairable + 1);
      } else if (state === "Delivered") {
        setDelivered((delivered) => delivered + 1);
      } else if (state === "N.R. Delivered") {
        setNrDelivered((nrDelivered) => nrDelivered + 1);
      }
    });
    setLoading(false);
  }, [pickDate, status]);

  useEffect(() => {
    setLoading(true);
    setQuotCount(0);
    setPaymentCount(0);
    setExpenses(0);

    misc.forEach((mis) => {
      setQuotCount((quotCount) => quotCount + parseInt(mis.quotation));
      setPaymentCount((paymentCount) => paymentCount + parseInt(mis.payment));
      setExpenses((expenses) => expenses + parseInt(mis.expense));
    });
    setLoading(false);
  }, [pickDate, misc]);

  //set offExpCount
  useEffect(() => {
    setLoading(true);
    setOffExpCount(0);

    offExp.forEach((exp) => {
      setOffExpCount((expCount) => expCount + parseInt(exp));
    });
    setLoading(false);
  }, [offExp]);

  useEffect(() => {
    setLoading(true);
    setDue(0);
    setProfit(0);

    setDue(parseInt(quotCount - paymentCount));
    setProfit(parseInt(paymentCount - expenses));
    setLoading(false);
  }, [paymentCount, quotCount, expenses]);

  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "unset";
  }, [loading]);

  //===>> Download CSV

  useEffect(() => {
    if (downloadToggle) {
      setLoading(true);

      const fetch = async () => {
        const customerRef = db.collection("customers");

        const document = await customerRef
          .where("timestamp", ">=", `${queryDate}`)
          .get();

        document.forEach(async (doc) => {
          let bills = await customerRef
            .doc(doc.id)
            .collection("bills")
            .where("timestamp", ">=", `${queryDate}`)
            .get();

          bills.forEach((bill) => {
            const jobData = bill.data().jobData;

            const id = new Date(parseInt(bill.id)).toLocaleDateString();

            const setData = {
              Name: jobData?.customer?.name,
              Number: jobData?.customer?.number,
              JobId: jobData?.product[0].billNo,
              Email: jobData?.customer?.email,
              Address: jobData?.customer?.address,
              Quotation: jobData?.misc?.quotation,
              Payment: jobData?.misc?.payment,
              Due: jobData?.misc?.due,
              Expense: jobData?.misc?.expense,
              C_Date: id,
              D_Date: jobData?.misc?.date,
              Product: jobData?.product[0].product,
              Brand: jobData?.product[0].brand,
              Model: jobData?.product[0].model,
              Serial: jobData?.product[0].serial,
              Problem: jobData?.product[0].problem,
              Accessories: jobData?.product[0].accessories,
              Remarks: jobData?.product[0].remark,
              Spare: jobData?.product[0].spareReplaced,
              Status: jobData?.product[0].status,
            };

            setCustomers((customer) => [...customer, setData]);
          });
        });
      };

      fetch();
      setLoading(false);
    } else {
      setCustomers([]);
    }
  }, [downloadToggle, queryDate]);

  //set User
  if (!context.user?.uid || context.user?.whoAmI !== "admin") {
    return <Redirect to="/signin" />;
  }

  return (
    <div className="dashboard">
      {loading && (
        <div className="loading">
          <Loader
            visible={loading}
            type="BallTriangle"
            color="#ffffff"
            height={80}
            width={80}
          />
        </div>
      )}
      <Row className="dashboard__headerSec justify-content-center">
        <Col className="dashboard__header m-5 d-md-flex justify-content-between">
          <h1 className="display-4">Hello Admin</h1>
          <div className="align-self-center buttons">
            <div className="download">
              <img
                onClick={(e) => setDownloadToggle(!downloadToggle)}
                src="https://img.icons8.com/nolan/64/download.png"
                alt=""
              />
              {downloadToggle && (
                <CSVLink
                  filename={`${new Date().toLocaleDateString()}.csv`}
                  data={customers}
                  asyncOnClick={true}
                  className="download__btn"
                >
                  Download
                </CSVLink>
              )}
            </div>
            <input
              onChange={(e) => setPickDate(new Date(e.target.value))}
              type="date"
              id="pickDate"
              value={pickDate.toISOString().substr(0, 10)}
              // value={`${new Date(pickDate).getFullYear()}-${(
              //   "0" +
              //   new Date(pickDate).getMonth() +
              //   1
              // ).slice(-2)}-${("0" + new Date(pickDate).getDate()).slice(-2)}`}
            />
          </div>
        </Col>
      </Row>

      <Row className="dashboard__paymentSec">
        <Col xs={12}>
          <Row>
            <Col className="dashboard__title" sm={12}>
              <h2>Transactions</h2>
            </Col>
            <Col>
              <div className="dashboard__payments">
                <h1 className="display-4">₹{quotCount}</h1>
                <h3>Quotation</h3>
              </div>
            </Col>
            <Col>
              <div className="dashboard__payments">
                <h1 className="display-4">₹{paymentCount}</h1>
                <h3>Payment</h3>
              </div>
            </Col>
            <Col>
              <div className="dashboard__payments">
                <h1 className="display-4">₹{expenses}</h1>
                <h3>Expenses</h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to={`/due-list/${queryDate}`}>
                <div className="dashboard__payments">
                  <h1 className="display-4">₹{due}</h1>
                  <h3>Due</h3>
                </div>
              </Link>
            </Col>
            <Col>
              <div className="dashboard__payments">
                <h1 className="display-4">₹{profit}</h1>
                <h3>Profit</h3>
              </div>
            </Col>
            <Col>
              <Link to={`expense/${queryDate}`}>
                <div className="dashboard__payments">
                  <h1 className="display-4">₹{offExpCount}</h1>
                  <h3>Office Exp</h3>
                </div>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="dashboard__statusSec">
        <Col className="dashboard__title" sm={12}>
          <h2>STATUS</h2>
        </Col>
        <Col>
          <Link to={`/status/received/${queryDate}`}>
            <div className="dashboard__status">
              <h1>{received}</h1>
              <h3>Received</h3>
            </div>
          </Link>
        </Col>
        <Col>
          <Link to={`/status/inProgress/${queryDate}`}>
            <div className="dashboard__status">
              <h1>{inProgress}</h1>
              <h3>In Progress</h3>
            </div>
          </Link>
        </Col>
        <Col>
          <Link to={`/status/repaired/${queryDate}`}>
            <div className="dashboard__status">
              <h1>{repaired}</h1>
              <h3>Repaired</h3>
            </div>
          </Link>
        </Col>
        <Col>
          <Link to={`/status/notRepairable/${queryDate}`}>
            <div className="dashboard__status">
              <h1>{notRepairable}</h1>
              <h3>Not Repairable</h3>
            </div>
          </Link>
        </Col>
        <Col>
          <Link to={`/status/delivered/${queryDate}`}>
            <div className="dashboard__status">
              <h1>{delivered}</h1>
              <h3>Delivered</h3>
            </div>
          </Link>
        </Col>
        <Col>
          <Link to={`/status/nr-delivered/${queryDate}`}>
            <div className="dashboard__status">
              <h1>{nrDelivered}</h1>
              <h3>N. R. Delivered</h3>
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
