import React, { useContext, useEffect, useState } from "react";
import "./Styles/Signin.css";
import { auth } from "../firebase";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { UserContext } from "../Context";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";

function Signin() {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let mount = true;
    await auth.signInWithEmailAndPassword(email, password).catch((error) => {
      if (error.code === "auth/user-not-found") {
        if (mount) {
          setMsg("Please check the credentials!");
        }
        return;
      } else if (error.code === "auth/wrong-password") {
        if (mount) {
          setMsg("Password is incorrect!");
        }
        return;
      } else {
        if (mount) {
          setMsg(error.code);
        }
      }
    });

    setLoading(false);
    return () => (mount = false);
  };

  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "unset";
  }, [loading]);

  if (context.user?.uid) {
    if (context.user?.email === "admin@cfs.com") {
      return <Redirect to="/dashboard" />;
    } else if (context.user?.email === "frontdesk@cfs.com") {
      return <Redirect to="/cfs/job/generate/form" />;
    } else if (context.user?.email === "eng@cfs.com") {
      return <Redirect to="/engineer" />;
    }
  }

  return (
    <Container fluid className="signin">
      {loading && (
        <div className="loading">
          <Loader
            visible={loading}
            type="BallTriangle"
            color="#ffffff"
            height={80}
            width={80}
          />
        </div>
      )}
      <Row>
        <Col md={4} className="offset-md-4 signin__Sec">
          <h1>Sign In</h1>
          {msg !== "" && (
            <Row>
              <Alert className="text-center signin__alert" variant="danger">
                <p>{msg}</p>
              </Alert>
            </Row>
          )}
          <form onSubmit={handleSubmit}>
            <div className="signin__inputSec">
              <label htmlFor="email">ID</label>
              <input
                placeholder="example@cfs.com"
                id="email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="password">Passcode</label>
              <input
                placeholder="Passcode"
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Button
              type="submit"
              className="mt-3"
              block
              variant="dark"
              onClick={handleSubmit}
            >
              Sign In
            </Button>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default Signin;
