import React, { useContext, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { UserContext } from "../Context";
import { db } from "../firebase";
import logo from "../Assets/logo.png";
import "./Styles/BillPage.css";
import "./Styles/MemoPage.css";

function BillPage() {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const { customer_num, bill_num } = useParams();
  const [billDetails, setBillDetails] = useState([]);
  const [prodList, setProdList] = useState([]);
  const [product, setProduct] = useState([]);

  useEffect(() => {
    setLoading(true);
    let mount = true;
    const getData = async () => {
      const billData = await db
        .collection("customers")
        .doc(customer_num)
        .collection("bills")
        .doc(bill_num)
        .get();

      if (!billData.exists) {
        setBillDetails([]);
        setProdList([]);
        return;
      } else {
        if (mount) {
          const jobs = billData.data();
          setBillDetails(jobs);

          const prodData = jobs.jobData?.product;
          setProdList(prodData);
        }
      }
    };

    getData();
    setLoading(false);
    return () => (mount = false);
  }, [customer_num, bill_num]);

  useEffect(() => {
    setLoading(true);
    let mount = true;
    prodList.forEach((prod) => {
      setProduct([]);
      const job = async () => {
        const data = await db
          .collection("jobs")
          .where("jobID", "==", `${prod.billNo}`)
          .get();
        if (data.empty) {
          setProdList([]);
          return;
        } else {
          if (mount) {
            data.forEach((doc) => {
              setProduct((product) => [...product, doc.data().product]);
            });
          }
        }
      };
      job();
    });
    setLoading(false);
    return () => (mount = false);
  }, [prodList]);

  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "unset";
  }, [loading]);

  const customer = billDetails.jobData?.customer;
  const misc = billDetails.jobData?.misc;
  const time = new Date(parseInt(billDetails.timestamp));
  const billTime = time.toLocaleDateString();

  // console.log(misc);

  //set User
  // if (!context.user?.uid || context.user?.whoAmI === "eng") {
  //   return <Redirect to="/signin" />;
  // }

  return (
    <>
      {context.user?.uid && context.user?.whoAmI !== "eng" && (
        <div className="billPage">
          <div className="bg__image">
            <img src={logo} alt="" />
          </div>
          {loading && (
            <div className="loading">
              <Loader
                visible={loading}
                type="BallTriangle"
                color="#ffffff"
                height={80}
                width={80}
              />
            </div>
          )}
          <div className="billPage__header">
            <h1>COMPUFACT SYSTEMS</h1>
            <h2>INVOICE</h2>
          </div>
          <div className="billPage__details">
            <div>
              <h6>50 SUBODH GARDEN (1st FLOOR) BANSDRONI KOLKATA - 700070</h6>
              <h6>Phone - 9830723620 / 8777626185</h6>
              <h6>Email : compufactsystems@gmail.com</h6>
              <h6>Website: www.compufactsystems.com</h6>
            </div>
            <div>
              <h6>DATE : {billTime}</h6>
              <h6>INVOICE No : {bill_num}</h6>
            </div>
          </div>
          <div>
            <h5>TO: </h5>
            <Row className="billPage__customer">
              <Col>
                <h6>{customer?.name}</h6>
                <h6>Contact: {customer?.number}</h6>
                <h6>Email: {customer?.email}</h6>
              </Col>
              <Col>
                <h6>Address: {customer?.address}</h6>
              </Col>
            </Row>
          </div>
          {/* <div>
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Job Id</th>
                  <th>Product</th>
                  <th>Model No</th>
                  <th>Serial No</th>
                  <th>Problem</th>
                  <th>Remarks</th>
                  <th>Total Am.</th>
                </tr>
              </thead>
              <tbody>
                {product?.map((prod, i) => {
                  const sl = i + 1;
                  return (
                    <tr key={`${prod.billNo}`}>
                      <td>{sl}</td>
                      <td>{prod.billNo}</td>
                      <td>{prod.product}</td>
                      <td>{prod.model}</td>
                      <td>{prod.serial}</td>
                      <td>{prod.problem}</td>
                      <td>{prod.remark}</td>
                      <td>{misc?.payment}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div> */}

          <div className="memoPage__productSec">
            <h5>Product Details: </h5>
            {product?.map((prod) => (
              <div className="memoPage__product" key={`${prod.billNo}`}>
                <h6>
                  <b>JOB NO: {prod.billNo}</b>
                </h6>
                <ul>
                  <Row>
                    <Col>
                      <li>
                        <b>Product :</b> {prod.product}
                      </li>
                      <li>
                        <b>Brand :</b> {prod.brand}
                      </li>
                      <li>
                        <b>Model No :</b> {prod.model}
                      </li>
                      <li>
                        <b>Serial No :</b> {prod.serial}
                      </li>
                    </Col>
                    <Col>
                      <li>
                        <b>Spare Replaced :</b> {prod.spareReplaced}
                      </li>

                      {/* <li>
                        <b>Other Accs :</b> {prod.accessories}
                      </li>
                      <li>
                        <b>Spare Required :</b> {prod.spareRequired}
                      </li> */}

                      <li>
                        <b>Problem :</b> {prod.problem}
                      </li>

                      <li>
                        <b>Remark :</b> {prod.remark}
                      </li>
                      <li>
                        <b>Status :</b> {prod.status}
                      </li>
                    </Col>
                  </Row>
                </ul>
              </div>
            ))}
          </div>

          <div className="memoPage__productSec">
            <h5>Payments: </h5>
            {misc && (
              <div className="billPage__paymentSec">
                <div className="billPage__amount">
                  <p>Spare Cost : </p>
                  <p>{`${misc?.expense} Rs`}</p>
                </div>
                <div className="billPage__amount">
                  <p>Service Cost : </p>
                  <p>{`${misc?.quotation - misc?.expense} Rs`}</p>
                </div>
                <div className="billPage__amount">
                  <p>Total : </p>
                  <p>{`${misc?.quotation} Rs`}</p>
                </div>
              </div>
            )}
          </div>

          <h5>Terms & Condition</h5>
          <div className="billPage__terms">
            <ol>
              <li>
                In this product serviced or repaired, a 30 days service warranty
                is provided on our behalf.
              </li>
            </ol>
          </div>

          <div className="billPage__signContainer">
            <div className="billPage__sign">Stamp & Sign</div>
          </div>
        </div>
      )}
    </>
  );
}

export default BillPage;
